import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Logo from 'layouts/components/Menu/images/logo.svg'
import { Tile } from './Tile'
import { EasyProcess, FastTime, QualifiedDoctors } from '../../images'
import { OfferItem } from './OfferItem'

export const WhyUs = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const tiles = [
    { description: t('landingPage.whyUsSection.easyProcess'), icon: EasyProcess },
    { description: t('landingPage.whyUsSection.fastTime'), icon: FastTime },
    { description: t('landingPage.whyUsSection.qualifiedDoctors'), icon: QualifiedDoctors }
  ]

  const offerTitles = [
    { header: t('landingPage.whyUsSection.offerCol1') },
    { header: t('landingPage.whyUsSection.offerCol2') },
    { header: t('landingPage.whyUsSection.offerCol3') }
  ]

  const offerItems = [
    {
      name: t('landingPage.whyUsSection.offerItem1'),
      description: t('landingPage.whyUsSection.offerDescription1'),
      price: t('landingPage.whyUsSection.offerPrice1')
    },
    {
      name: t('landingPage.whyUsSection.offerItem2'),
      description: t('landingPage.whyUsSection.offerDescription2'),
      price: t('landingPage.whyUsSection.offerPrice2')
    },
    {
      name: t('landingPage.whyUsSection.offerItem3'),
      description: t('landingPage.whyUsSection.offerDescription3'),
      price: t('landingPage.whyUsSection.offerPrice3')
    }
  ]

  return (
    <Grid id="whyUs" container direction="column" justifyContent="center" className={classes.sectionWrapper}>
      <Grid item container justifyContent="center" alignItems="center">
        <Typography variant="h2">{t('landingPage.whyUsSection.header')}&nbsp;</Typography>
        <img src={Logo} alt="Logo" />
        <Typography variant="h2">&nbsp;?</Typography>
      </Grid>
      <Grid container item justifyContent="center">
        {tiles.map((tile) => (
          <Tile description={tile.description} img={tile.icon} key={tile.description} />
        ))}
      </Grid>
      <Grid container item className={classes.offerWrapper}>
        <Grid container className={classes.offerTitles}>
          {offerTitles.map((title) => (
            <Typography variant="h4" key={title.header}>
              {title.header}
            </Typography>
          ))}
        </Grid>
        {offerItems.map((item) => (
          <OfferItem name={item.name} description={item.description} price={item.price} key={item.name} />
        ))}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    minHeight: '100vh',

    '& > div:first-of-type': {
      marginBottom: theme.spacing(6)
    },

    '& > div > img': {
      width: '18%'
    },

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      alignItems: 'center',

      '& > div > img': {
        width: '30%'
      }
    },

    [theme.breakpoints.down('xs')]: {
      '& > div > img': {
        width: '50%'
      }
    }
  },
  offerWrapper: {
    margin: `10vh 0`,
    boxShadow: `0px 2px 11px 0px ${theme.customColors.lightBlack}`,
    minHeight: '27vh',
    borderRadius: '20px'
  },
  offerTitles: {
    backgroundColor: `${theme.customColors.backgroundGray}`,
    boxShadow: `0px 3px 8px 0px ${theme.customColors.black}1a`,
    borderRadius: '20px',

    '& h4': {
      fontSize: '1.5rem',
      padding: `${theme.spacing(2)}`,

      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '1rem'
      }
    },

    '& > *': {
      color: `${theme.palette.primary.main}`,
      width: '33%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: `1px solid ${theme.customColors.lightGrey}`,

      '&:last-child': {
        borderRight: 'none'
      }
    }
  }
}))
