import { makeStyles, Typography, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TwoColorHeader } from 'common/components/TwoColorHeader'
import { SubmitHandler } from 'react-hook-form'
import { StartingContactDataForm } from 'features/survey/types'
import { DataForm } from './DataForm'

interface StartingContactDataFormProps {
  handleSubmit: SubmitHandler<StartingContactDataForm>
  isProcessing?: boolean
  isSuccess: boolean
  isError: boolean
}

export const ContactDataForm = ({
  handleSubmit,
  isProcessing,
  isSuccess,
  isError
}: StartingContactDataFormProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid
      container
      item
      direction="column"
      xs={12}
      sm={8}
      alignItems="center"
      justifyContent="center"
      className={classes.contactWrapper}
    >
      <TwoColorHeader translationPath="surveyPage.contactDataForm.header" />
      <Typography className={classes.description}>{t('surveyPage.contactDataForm.description')}</Typography>
      <DataForm onSubmit={handleSubmit} isProcessing={isProcessing} isSuccess={isSuccess} isError={isError} />
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  contactWrapper: {
    height: '100%',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'unset',
      flexWrap: 'nowrap'
    }
  },
  description: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      textAlign: 'center'
    }
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))
