import { apiClient } from 'common/utils/api'
import { authenticatedUserResponseSchema } from 'features/login/api'

export const refreshTokenData = async () => {
  const response = await apiClient()
    .post('Accounts/refresh-token', {
      credentials: 'include'
    })
    .json()
  return authenticatedUserResponseSchema.parse(response)
}
