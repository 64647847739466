import { Typography, makeStyles } from '@material-ui/core'

interface QuestionHeaderProps {
  headerData: {
    regularStart?: string
    highlightedPart?: string
    regularEnd?: string
  }
  description?: string
}

export const QuestionHeader = ({
  headerData: { regularStart, highlightedPart, regularEnd },
  description
}: QuestionHeaderProps) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography variant="h1" align="center" className={classes.header}>
        {regularStart} <span className={classes.highlighted}>{highlightedPart}</span> {regularEnd}
      </Typography>
      {description}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  highlighted: {
    color: theme.palette.primary.main
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    marginBottom: theme.spacing(3)
  }
}))
