import { apiClient } from 'common/utils/api'

export const revokeToken = async (token: string) => {
  await apiClient(token)
    .post('Accounts/revoke-token', {
      credentials: 'include',
      json: {}
    })
    .json()
}
