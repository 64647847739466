import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { FormTextField, Button } from 'common/components'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { PromoCodeFormData, promoCodeSchema } from 'features/summary'
import { useEffect } from 'react'

interface PromoCodeProps {
  onSubmit: SubmitHandler<PromoCodeFormData>
  isSubmitError: boolean
  isProcessing?: boolean
  isPromoCodeApplied: boolean
}

export const PromoCode = ({ onSubmit, isProcessing, isSubmitError, isPromoCodeApplied }: PromoCodeProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const formMethods = useForm<PromoCodeFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(promoCodeSchema)
  })

  useEffect(() => {
    if (isSubmitError) {
      formMethods.setError('promoCode', { message: 'errors.invaidPromoCode' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitError])

  return (
    <FormProvider {...formMethods}>
      <form className={classes.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FormTextField
          className={classes.fieldWrapper}
          label={t('summary.promoCode')}
          disabled={isProcessing || isPromoCodeApplied}
          name="promoCode"
          placeholder={t('summary.promoCodePlaceholder')}
          InputProps={{
            startAdornment: (
              <Button
                className={classes.submitButton}
                variant="outlined"
                type="submit"
                disabled={isProcessing || isPromoCodeApplied}
              >
                {t('summary.checkPromoCode')}
              </Button>
            )
          }}
        />
      </form>
    </FormProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    '& > .MuiFormControl-root': {
      padding: 0,
      '& > label': {
        fontSize: '1rem',
        fontWeight: 600,
        padding: `0 0 ${theme.spacing(1)} ${theme.spacing(3)}`
      }
    }
  },
  fieldWrapper: {
    flexDirection: 'row-reverse',
    borderRadius: '5.25rem',
    padding: 0,
    backgroundColor: theme.customColors.white,
    '& > label': {
      fontSize: '1rem',
      fontWeight: 900
    },
    '& input': {
      boxShadow: 'none'
    }
  },
  submitButton: {
    width: '50%',
    border: 'none !important',
    margin: 0,
    color: theme.palette.primary.main,
    padding: '1rem',
    fontSize: '1rem',

    [theme.breakpoints.down('sm')]: {
      width: '40%'
    },

    '& span': {
      display: 'flex'
    }
  }
}))
