import ky from 'ky'
import { RawQuestionData, RawPossibleMedications } from 'features/survey/types'

import { parseQuestionsData } from 'features/survey/utils/quesionParser'

const dataUrl = process.env.REACT_APP_STATIC_DATA_API_URI as unknown as string

interface StaticDataResponse {
  questions: RawQuestionData[]
  possibleMedications: RawPossibleMedications[]
}

export const fetchStaticData = () =>
  ky
    .get(dataUrl)
    .json()
    .then((res) => {
      const parsedQuestionData = parseQuestionsData((res as StaticDataResponse).questions)
      const { possibleMedications } = res as StaticDataResponse

      return {
        questions: parsedQuestionData,
        possibleMedications
      }
    })
