import { Box, makeStyles, Typography } from '@material-ui/core'
import cookiesIcon from 'common/icons/cookie_icon.svg'
import cookiesCloseIcon from 'common/icons/close_cookies_icon.svg'
import { useTranslation } from 'react-i18next'
import { setCookie } from 'common/utils/cookies'
import { Dispatch, SetStateAction } from 'react'

interface ICookiesBar {
  setShowCookie: Dispatch<SetStateAction<boolean>>
}

export const CookiesBar = ({ setShowCookie }: ICookiesBar) => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <Box className={classes.wrapper}>
      <img src={cookiesIcon} alt="Cookies icon" />
      <Box className={classes.content}>
        <Typography variant="h3">{t('cookiesBar.header')}</Typography>
        <Typography variant="body1">{t('cookiesBar.description1')}</Typography>
        <Typography variant="body1">{t('cookiesBar.description2')}</Typography>
      </Box>
      <button
        onClick={() => {
          setShowCookie(false)
          setCookie('drugly_cookies_accepted', 'true', 30)
        }}
        className={classes.closeIcon}
        type="button"
      >
        close
      </button>
    </Box>
  )
}

const useStyle = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.customColors.backgroundGray,
    padding: `3vh 138px`,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: `3vh 0`
    },

    '& > img': {
      height: '50%',

      [theme.breakpoints.down('sm')]: {
        height: '75%'
      }
    }
  },
  content: {
    padding: `0 0 0 ${theme.spacing(8)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: `3vh 6vw`
    },

    '& > h3': {
      color: theme.customColors.white,
      fontWeight: 700,
      fontSize: '1.125rem',
      margin: `0 0 ${theme.spacing(0.75)} 0`,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem'
      }
    },

    '& > p': {
      color: theme.customColors.white,
      fontWeight: 400,
      fontSize: '0.875rem',
      margin: `${theme.spacing(0.25)} 0 0 0`,
      lineHeight: '1.15em',

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.5rem',
        margin: `${theme.spacing(1)} 0 0 0`,

        '&:nth-of-type(2)': {
          margin: `${theme.spacing(2)} 0 0 0`
        }
      }
    }
  },
  closeIcon: {
    backgroundColor: 'transparent',
    border: 'none',
    backgroundImage: `url(${cookiesCloseIcon})`,
    color: 'transparent',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '16px',
    right: '19px',
    width: '30px',
    height: '30px',

    '&:hover': {
      cursor: 'pointer'
    }
  }
}))
