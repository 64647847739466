const currencyCodes: Record<string, string> = {
  pl: 'PLN'
}

export const formatPrice = (value: number, currency: string) => {
  return new Intl.NumberFormat('pl', {
    style: 'currency',
    currency: currencyCodes[currency]
  }).format(value)
}
