import { Button as MuiButton, ButtonProps as MuiButtonProps, makeStyles, Theme } from '@material-ui/core'
import { Loader } from 'common/components'
import { SuccessTickIcon } from './SuccessTickIcon'

interface ButtonProps extends Omit<MuiButtonProps, 'onChange'> {
  handleButtonClick?: () => void
  isLoading?: boolean
  isSuccess?: boolean
}

export const Button = ({ children, handleButtonClick, isLoading, isSuccess, ...rest }: ButtonProps) => {
  const isInteractingWithForm = isLoading || isSuccess

  const classes = useStyle({ isInteractingWithForm })

  return (
    <MuiButton disabled={isInteractingWithForm} className={classes.formButton} {...rest} onClick={handleButtonClick}>
      {isLoading && <Loader className={classes.buttonLoader} size={50} />}
      {isSuccess && !isLoading && <SuccessTickIcon />}
      {children}
    </MuiButton>
  )
}

const useStyle = makeStyles<Theme, { isInteractingWithForm: boolean | undefined }>(() => ({
  formButton: {
    '& span': {
      color: (props) => (props.isInteractingWithForm ? 'rgba(0, 0, 0, 0)' : 'inherit')
    },

    '& svg': {
      color: 'white'
    }
  }
}))
