import { apiClient } from 'common/utils/api'
import * as z from 'zod'

const afterPaymentDataSchema = z.object({
  userId: z.string(),
  paymentId: z.string(),
  dateFrom: z.string(),
  dateTo: z.string(),
  eventTypeId: z.number(),
  couponCode: z.optional(z.string()),
  doctorId: z.number(),
  userAnswers: z
    .object({
      question: z.string(),
      answer: z.string()
    })
    .array(),
  jwtToken: z.string()
})

export type AfterPaymentData = z.TypeOf<typeof afterPaymentDataSchema>

export const submitAfterPayment = async (requestData: AfterPaymentData): Promise<void> => {
  const { jwtToken, ...rest } = requestData
  await apiClient(jwtToken).post('AfterPaymentProcess', {
    json: {
      ...rest
    }
  })
}

interface Questions {
  [idPytania: string]: {
    questionTitle: string
    userChoice: string
  }
}

export const parseQuestions = (questions: Questions) => {
  return Object.keys(questions).map((key) => ({
    question: questions[key].questionTitle,
    answer: questions[key].userChoice
  }))
}
