import { Grid, makeStyles } from '@material-ui/core'
import { AnswerPill } from 'common/components'

/**
  Usage:
  const optionsLabels = ['one']
  const { options, toggleOption } = useMultipleSelectionState(optionsLabels)

  return <AnswerPillsWrapper options={options} toggleOption={toggleOption} />
*/

interface AnswerPillsWrapperProps {
  options: {
    label: string
    styledLabel?: JSX.Element
    isChecked?: boolean
  }[]
  toggleOption: (index: number) => void
  isColumn?: boolean
}

export const AnswerPillsWrapper = ({ options, toggleOption, isColumn }: AnswerPillsWrapperProps) => {
  const classes = useStyle()

  return (
    <Grid
      container
      className={classes.wrapper}
      direction={isColumn ? 'column' : 'row'}
      justifyContent={isColumn ? undefined : 'center'}
      alignItems={isColumn ? 'center' : undefined}
      spacing={1}
    >
      {options.map((pill, index) => (
        <Grid item key={pill.label}>
          <AnswerPill
            label={pill.styledLabel || pill.label}
            isChecked={pill.isChecked}
            toggleOption={() => toggleOption(index)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const useStyle = makeStyles((theme) => ({
  wrapper: {
    padding: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      '& div': {
        maxWidth: '100%'
      }
    }
  }
}))
