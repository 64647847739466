import { z } from 'zod'

export const contactFormSchema = z.object({
  name: z.string().nonempty('errors.required'),
  email: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.emailRequired')
        .email('errors.incorrectEmail')
        .safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    }),
  message: z.string().nonempty('errors.required'),
  termsAndConditions: z.boolean().superRefine((val, ctx) => {
    if (!val) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.termsAndConditionsRequired' })
    }
  })
})

export type ContactFormData = z.infer<typeof contactFormSchema>
