import {
  RawQuestionData,
  QuestionType,
  MultipleChoiceQuestion,
  SingleChoiceQuestion,
  TextQuestion,
  SurveyQuestion
} from '../types'

const questionMappings = {
  [QuestionType.multipleChoice]: (rawData: RawQuestionData) =>
    ({
      order: rawData.order,
      type: QuestionType.multipleChoice,
      title: rawData.title || {
        regularStart: '',
        highlightedPart: '',
        regularEnd: ''
      },
      options: rawData.options || [],
      wrongAnswer: rawData.wrongAnswer || '',
      inputPlaceholder: rawData.inputPlaceholder || ''
    } as MultipleChoiceQuestion),
  [QuestionType.singleChoice]: (rawData: RawQuestionData) =>
    ({
      order: rawData.order,
      type: QuestionType.singleChoice,
      title: rawData.title || {
        regularStart: '',
        highlightedPart: '',
        regularEnd: ''
      },
      description: rawData.description || '',
      yesText: rawData.yesText || '',
      noText: rawData.noText || '',
      correctAnswer: rawData.correctAnswer || true
    } as SingleChoiceQuestion),
  [QuestionType.text]: (rawData: RawQuestionData) =>
    ({
      order: rawData.order,
      type: QuestionType.text,
      title: rawData.title || {
        regularStart: '',
        highlightedPart: '',
        regularEnd: ''
      },
      inputPlaceholder: rawData.inputPlaceholder || '',
      showPossibleMedicationsHint: rawData.showPossibleMedicationsHint || false
    } as TextQuestion)
}

export const getQuestionTypeFromString = (type: string) =>
  Object.values(QuestionType)
    .map((value) => value.toUpperCase())
    .includes(type.toUpperCase())
    ? (type.toUpperCase() as QuestionType)
    : undefined

export const parseQuestionsData = (rawQuestionData: RawQuestionData[]) => {
  return rawQuestionData
    .map((questionData) => {
      const { type: stringType } = questionData
      const type = getQuestionTypeFromString(stringType)

      return type && questionMappings[type](questionData)
    })
    .filter((question) => !!question) as SurveyQuestion[]
}
