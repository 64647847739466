import { voivodeshipOptions } from 'common/constants'
import { apiClient } from 'common/utils/api'
import { getValuesFromPesel } from 'common/utils/validatePesel'
import { AccountSettingsFormData } from 'features/account/types'

interface UpdatePatientDetailsData extends AccountSettingsFormData {
  token: string
  userId: string
  initialUserEmail?: string
}

const parseNfz = (voivedeship: string) => {
  const index = voivodeshipOptions.indexOf(voivedeship) + 1
  return index < 10 ? `0${index}` : `${index}`
}

export const updatePatientDetailsRequest = async (requestData: UpdatePatientDetailsData) => {
  const { birthDate, gender } = getValuesFromPesel(requestData.pesel)

  await apiClient(requestData.token).put('Patients', {
    json: {
      userId: requestData.userId,
      firstName: requestData.firstName,
      lastName: requestData.lastName,
      birthDate,
      gender,
      pesel: requestData.pesel,
      phone: requestData.phoneNumber,
      email: requestData.email,
      nfz: parseNfz(requestData.voivodeship),
      address: {
        zip: requestData.postalCode,
        city: requestData.city,
        street: requestData.street,
        houseNumber: requestData.houseNumber,
        apartmentNumber: requestData.apartmentNumber
      }
    }
  })

  if (requestData.initialUserEmail !== requestData.email) {
    await apiClient(requestData.token)
      .put(`Accounts/${requestData.userId}`, {
        json: { email: requestData.email }
      })
      .json()
  }
}
