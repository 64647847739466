export enum RoutesDefinition {
  landing = '/',

  survey = '/survey',
  surveyStart = '/survey/start',
  surveyQuestionnaire = '/survey/questionnaire',
  surveySuccess = '/survey/success',
  surveyProcessing = '/survey/processing',
  surveyFail = '/survey/fail',

  account = '/account',
  accountSettings = '/account/settings',
  accountDashboard = '/account/dashboard',
  accountCreate = '/account/create',

  authLogin = '/login',
  logout = '/logout',

  user = '/users',
  resetPassword = '/reset-password',
  resetPasswordSuccess = '/reset-password/success',
  setNewPassword = '/set-new-password',
  verifyEmail = '/verify-email',

  summary = '/summary',
  success = '/reservation/success',

  terms = '/terms-of-use'
}
