import { useNavigationElementsStyle } from 'common/styles/useNavigationElementsStyle'
import { TFuncKey, useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'
import { ReactScrollLinkProps } from 'react-scroll/modules/components/Link'

interface SectionLinkProps extends ReactScrollLinkProps {
  label: string
  className?: string
  handleClick?: () => void
}

export const SectionLink = ({ label, className, handleClick, to, offset }: SectionLinkProps): JSX.Element => {
  const classes = useNavigationElementsStyle()
  const { t } = useTranslation()

  return (
    <Link
      offset={offset}
      onClick={handleClick}
      smooth
      spy
      to={to}
      activeClass="active"
      className={className || classes.basicLink}
    >
      {t(label as TFuncKey)}
    </Link>
  )
}
