import { z } from 'zod'

export const forgetPasswordSchema = z.object({
  email: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.emailRequired')
        .email('errors.incorrectEmail')
        .safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    })
})

export type ForgetPasswordData = z.infer<typeof forgetPasswordSchema>
