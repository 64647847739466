import { Container, makeStyles, Typography } from '@material-ui/core'

interface FormSeparatorProps {
  label?: string
}

export const FormSeparator = ({ label }: FormSeparatorProps): JSX.Element => {
  const classes = useStyle()

  return (
    <Container maxWidth={false} className={classes.container}>
      <Typography variant="h6">{label}</Typography>
    </Container>
  )
}

const useStyle = makeStyles((theme) => ({
  container: {
    '& > h6': {
      color: theme.customColors.distinguishedLightGray,
      fontWeight: 700
    },
    borderBottom: `1.5px solid ${theme.customColors.opaqueGrey}`,
    padding: '0.5rem 0'
  }
}))
