import { createContext, useState, FC } from 'react'
import { Snackbar as MuiSnackbar } from '@material-ui/core'
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export interface SnackbarState {
  snack: {
    message: string
    severity: Color
    open: boolean
  }
  openSnackbar: (message: string, severity: Color) => void
}

export const snackbarDefaultValue: SnackbarState = {
  snack: { message: '', severity: 'error', open: false },
  openSnackbar: () => {}
}

export const SnackbarContext = createContext(snackbarDefaultValue)

export const SnackbarWrapper: FC = ({ children }) => {
  const [snack, setSnack] = useState({
    message: '',
    severity: 'error' as Color,
    open: false
  })

  const openSnackbar = (message: string, severity: Color) => setSnack({ message, severity, open: true })

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnack({ ...snack, open: false })
  }

  return (
    <SnackbarContext.Provider value={{ snack, openSnackbar }}>
      {children}
      <MuiSnackbar open={snack.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity={snack.severity}>{snack.message}</Alert>
      </MuiSnackbar>
    </SnackbarContext.Provider>
  )
}
