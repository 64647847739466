import { Grid, makeStyles } from '@material-ui/core'
import { AnswerPill } from 'common/components'

interface IsolatedAnswerPillProps {
  label: string
  isChecked?: boolean
  uncheckAll: () => void
  toggleIsolatedOption: () => void
}

export const IsolatedAnswerPill = ({ label, isChecked, uncheckAll, toggleIsolatedOption }: IsolatedAnswerPillProps) => {
  const classes = useStyle()

  return (
    <Grid container className={classes.wrapper} justifyContent="center">
      <Grid item key={label}>
        <AnswerPill
          label={label}
          isChecked={isChecked}
          toggleOption={() => {
            uncheckAll()
            toggleIsolatedOption()
          }}
        />
      </Grid>
    </Grid>
  )
}

const useStyle = makeStyles(() => ({
  wrapper: {
    padding: '3rem 0',
    '& div': {
      maxWidth: '100%'
    }
  }
}))
