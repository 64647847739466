import { Box, Fab, makeStyles } from '@material-ui/core'
import facebook from 'common/icons/facebook.svg'
import instagram from 'common/icons/instagram.svg'

export const FabBox = () => {
  const classes = useStyle()

  return (
    <Box className={classes.box}>
      <Fab target="_blank" href="https://www.facebook.com/druglyPL" size="medium" aria-label="facebook">
        <img src={facebook} alt="facebook" color="primary" />
      </Fab>
      <Fab target="_blank" href="https://www.instagram.com/drugly.pl/" size="medium" aria-label="instagram">
        <img src={instagram} alt="instagram" color="primary" />
      </Fab>
    </Box>
  )
}

const useStyle = makeStyles((theme) => ({
  box: {
    '& > a': {
      fontSize: '2rem',
      background: 'transparent',
      border: `1px solid ${theme.customColors.white}`,
      margin: `0 ${theme.spacing(1)}`,

      '& img': {
        width: '40%',

        [theme.breakpoints.down('xs')]: {
          width: '35%'
        }
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  }
}))
