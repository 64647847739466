import { makeStyles, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SubmitHandler } from 'react-hook-form'
import { TwoColorHeader } from 'common/components/TwoColorHeader'
import { ForgetPasswordData } from '../types'
import { ForgetPasswordForm } from './ForgetPasswordForm'

interface ForgetPasswordProps {
  onSubmit: SubmitHandler<ForgetPasswordData>
  isProcessing?: boolean
  isSuccess?: boolean
}

export const ForgetPassword = ({ onSubmit, isProcessing, isSuccess }: ForgetPasswordProps): JSX.Element => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <Grid container item xs={12} sm={8} md={6} justifyContent="center" alignItems="center" direction="column">
      <TwoColorHeader translationPath="forgetPassword.header" />
      <Typography className={classes.description}>{t('forgetPassword.descriprion')}</Typography>
      <ForgetPasswordForm onSubmit={onSubmit} isProcessing={isProcessing} isSuccess={isSuccess} />
    </Grid>
  )
}

const useStyle = makeStyles((theme) => ({
  description: {
    padding: `0 0 ${theme.spacing(4)} 0`,

    [theme.breakpoints.down('xs')]: {
      width: '90%',
      textAlign: 'center'
    }
  }
}))
