import { apiClient } from 'common/utils/api'
import { ForgetPasswordData } from '../types'

export const submitForgotPasswordForm = async ({ email }: ForgetPasswordData): Promise<void> => {
  await apiClient().post('Accounts/forgot-password', {
    json: {
      email
    }
  })
}
