import { apiClient } from 'common/utils/api'
import * as z from 'zod'

const paymentDataSchema = z.object({
  basePrice: z.number(),
  finalPrice: z.number(),
  userId: z.string(),
  userEmail: z.string(),
  couponId: z.optional(z.string()),
  jwtToken: z.string()
})

export type PaymentData = z.TypeOf<typeof paymentDataSchema>

const paymentResponseSchema = z.object({
  paymentId: z.string(),
  token: z.string()
})

export type PaymentRequestResponse = z.TypeOf<typeof paymentResponseSchema>

export const submitPayment = async (requestData: PaymentData): Promise<PaymentRequestResponse> => {
  const { jwtToken, ...rest } = requestData
  const response = await apiClient(jwtToken)
    .post('Payments', {
      json: {
        ...rest
      }
    })
    .json()

  return paymentResponseSchema.parse(response)
}
