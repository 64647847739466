import { makeStyles } from '@material-ui/core'
import tickIcon from '../../icons/tick_icon.svg'

export const SuccessTickIcon = (): JSX.Element => {
  const classes = useStyle()

  return (
    <div className={classes.iconWrapper}>
      <img src={tickIcon} alt="Sukces!" />
    </div>
  )
}

const useStyle = makeStyles(() => ({
  iconWrapper: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}))
