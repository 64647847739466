import { makeStyles, InputAdornment } from '@material-ui/core'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import clsx from 'clsx'

import { TextInput } from 'common/components/TextInput/TextInput'

import searchIcon from './searchIcon.svg'
import closeIcon from './closeIcon.svg'

interface SearchInputProps extends Partial<AutocompleteProps<string, false, true, false>> {
  value: string
  options: string[]
  onValueChange: (val: string) => void
  onClear: () => void
  placeholder?: string
  className?: string
}

export const SearchInput = ({
  value = '',
  placeholder = '',
  options,
  onValueChange,
  onClear,
  className,
  ...rest
}: SearchInputProps) => {
  const classes = useStyle()

  const isFilledIn = !!value

  return (
    <Autocomplete
      {...rest}
      value={value}
      options={options}
      onChange={(_, val) => onValueChange(val)}
      renderInput={({ InputProps, ...params }) => (
        <TextInput
          {...params}
          InputProps={{
            ...InputProps,
            startAdornment: [
              !isFilledIn && (
                <InputAdornment position="start" className={classes.icon} key="startAdorment">
                  <img src={searchIcon} alt="SearchIcon" />
                </InputAdornment>
              ),
              InputProps.startAdornment
            ],
            endAdornment: (
              <InputAdornment position="end" className={classes.icon} onClick={() => onClear()}>
                <img src={closeIcon} alt="closeIcon" />
              </InputAdornment>
            )
          }}
          placeholder={placeholder}
        />
      )}
      className={clsx(className, classes.autocomplete, isFilledIn && classes.filledIn)}
    />
  )
}

const useStyle = makeStyles((theme) => ({
  autocomplete: {
    width: '100%',
    display: 'block',
    margin: 0,
    padding: '1rem 0',

    '& > .MuiTextField-root': {
      boxShadow: theme.boxShadow.lightGrey,
      WebkitBoxShadow: theme.boxShadow.lightGrey,
      WebkitAppearance: 'none',
      fontSize: '1rem',
      color: theme.customColors.backgroundGray,
      backgroundColor: theme.customColors.white,
      borderRadius: '5.25rem',
      padding: 0,
      '& > input': {
        '-webkit-box-shadow': 'none'
      },
      '& > .MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd': {
        paddingRight: '1.5rem'
      }
    }
  },
  filledIn: {
    '& > .MuiTextField-root, input': {
      color: theme.customColors.white,
      backgroundColor: theme.customColors.backgroundGray,
      fontWeight: 600,
      '& input': {
        marginLeft: '1.5rem'
      }
    }
  },
  icon: {
    padding: '0.5rem 0rem 0.5rem 1.5rem'
  }
}))
