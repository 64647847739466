import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationPL from './translations/translationPL.json'

import { formatPrice } from './formatPrice'
import { formatDate } from './formatDate'

const fallbackLng = ['pl']
const supportedLngs = ['pl']

const resources = {
  pl: {
    translation: translationPL
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    supportedLngs,
    interpolation: {
      format(value, format, lng) {
        if (format === 'currency') {
          return formatPrice(value, lng || fallbackLng[0])
        }

        if (format === 'date') {
          return formatDate(value, lng || fallbackLng[0])
        }

        return value
      }
    }
  })

export default i18n
