import { makeStyles, OutlinedInputProps, TextField as MuiTextField, TextFieldProps } from '@material-ui/core'
import clsx from 'clsx'
import { mergeWith } from 'lodash'

export const TextInput = ({ InputProps = {}, className, defaultValue, inputRef, ...rest }: TextFieldProps) => {
  const classes = useStyle()

  const mergedInputProps: Partial<OutlinedInputProps> = {
    ...InputProps,
    className: clsx(classes.inputElement, InputProps.className, className),
    classes: mergeWith(
      {
        error: 'errorInput'
      },
      InputProps.classes,
      (base, additional) => clsx(base, additional)
    )
  }

  return (
    <MuiTextField
      {...rest}
      defaultValue={defaultValue}
      variant="outlined"
      className={classes.textField}
      InputProps={mergedInputProps}
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}

const useStyle = makeStyles((theme) => ({
  textField: {
    width: '100%',
    display: 'block',
    margin: 0,
    padding: '1rem 0 0',
    '& > .MuiInputLabel-root': {
      zIndex: 0,
      position: 'relative',
      transform: 'none',
      fontSize: '1rem !important',
      fontWeight: '700 !important',
      color: theme.palette.secondary.dark,
      letterSpacing: '0.0125rem',
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(4)
    },
    '& > .MuiOutlinedInput-root': {
      width: '100%'
    },
    '& > .MuiOutlinedInput-multiline': {
      padding: 0
    },
    '& span': {
      display: 'none'
    },
    '& legend': {
      height: '11px'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginRight: 0
    },
    '& > .MuiFormLabel-root': {
      fontSize: 'clamp(1.3em, 1.7vw, 2em)',
      fontWeight: 400,
      color: theme.palette.text.primary
    }
  },
  inputElement: {
    '& > input, textarea': {
      boxShadow: theme.boxShadow.lightGrey,
      WebkitBoxShadow: theme.boxShadow.lightGrey,
      WebkitAppearance: 'none',
      padding: '1.325rem 2rem',
      fontSize: '1rem',
      color: theme.customColors.backgroundGray,
      backgroundColor: theme.customColors.white,

      [theme.breakpoints.down('sm')]: {
        padding: '1.325rem 1.5rem'
      },

      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },

      '&[type=number]': {
        '-moz-appearance': 'textfield'
      },

      '&::placeholder': {
        color: theme.customColors.opaqueGrey,
        opacity: 1
      },

      '&:-ms-input-placeholder': {
        color: theme.customColors.opaqueGrey
      },

      '&::-ms-input-placeholder': {
        color: theme.customColors.opaqueGrey
      },
      '&.Mui-disabled': {
        opacity: 0.5,
        color: theme.customColors.opaqueGrey,
        boxShadow: 'none',
        WebkitBoxShadow: 'none'
      },
      '&:read-only': {
        opacity: 0.5,
        color: theme.customColors.opaqueGrey,
        boxShadow: 'none',
        WebkitBoxShadow: 'none'
      }
    },

    '& > input': {
      borderRadius: '5.25rem'
    },

    '& > fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius: '5.25rem'
    },

    '&.MuiInputBase-multiline': {
      '& > fieldset.MuiOutlinedInput-notchedOutline': {
        borderRadius: '2.25rem',

        [theme.breakpoints.down('xs')]: {
          borderRadius: '1.55rem'
        }
      },

      '& > textarea': {
        borderRadius: '2.25rem',
        [theme.breakpoints.down('xs')]: {
          borderRadius: '1.55rem'
        }
      }
    },

    '&.errorInput': {
      '& > fieldset.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '5.25rem'
      }
    }
  }
}))
