export const YEAR_MONTH_DAY = 'YYYY-MM-DD'
export const DOTTED_DAY_MONTH_YEAR = 'DD.MM.YYYY'
export const YEAR_MONTH = 'YYYY-MM'
export const DAY_MONTH_NAME = 'dd MMMM'
export const MONTH_NAME_YEAR = 'LLLL YYYY'
export const TIME_FORMAT = 'HH:mm'
export const FULL_DAY_NAME = 'dddd'
export const DAY_MONTH_HOUR_MINUTE_NAME = 'DD MMMM HH:mm'
export const DAY_MONTH = 'DD MMMM'
export const YEAR_MONTH_DAY_MINUTES_SEC = 'YYYY-MM-DD HH:mm:ss'
