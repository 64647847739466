import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface TileProps {
  img: string
  description: string
}

export const Tile = ({ img, description }: TileProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.iconContainer}
      wrap="nowrap"
    >
      <img src={img} alt={description} />
      <Typography variant="body1">
        <b>{description}</b>
      </Typography>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    boxShadow: `0px 2px 11px 0px ${theme.customColors.lightBlack}`,
    width: '20%',
    height: '25vh',
    borderRadius: '20px',
    margin: '0 1.75vw ',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '30%',
      height: '100%',
      margin: '0 0.5vw',
      padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
      minHeight: '17.5vh',

      '& > img': {
        width: '35%'
      }
    },

    '& > p': {
      textAlign: 'center',
      fontSize: '1.2rem',
      wordBreak: 'normal',
      width: '80%',

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.625rem',
        margin: '0.5rem 0 0 0',
        width: '100%'
      }
    }
  }
}))
