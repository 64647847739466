import { Container, Grid, makeStyles } from '@material-ui/core'
import {
  Checkbox,
  FormSeparator,
  FormTextField,
  PasswordInput,
  Button,
  Select,
  MaskedInput,
  LinkText
} from 'common/components'
import { CreateFormData, createFormSchema } from 'features/account'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { voivodeshipOptions } from 'common/constants/voivodeships'
import { peselMask, zipMask } from 'common/constants'
import { RoutesDefinition } from 'routing/constants/RoutesDefinition'

interface AccountCreateFormProps {
  onSubmit: SubmitHandler<CreateFormData>
  isError: boolean
  isProcessing?: boolean
}

export const AccountCreateForm = ({ isProcessing, onSubmit, isError }: AccountCreateFormProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const formMethods = useForm<CreateFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(createFormSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
      pesel: '',
      street: '',
      houseNumber: '',
      city: '',
      zip: '',
      voivodeship: '',
      termsAndConditions: false
    }
  })

  useEffect(() => {
    if (isError) {
      formMethods.setError('termsAndConditions', { message: 'errors.formError' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <FormProvider {...formMethods}>
      <form className={classes.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
        {/* Password */}
        <Container className={classes.container}>
          <FormSeparator label={t('accountCreatePage.setPassword')} />
          <PasswordInput
            name="password"
            label={t('accountCreatePage.password')}
            placeholder={t('accountCreatePage.password')}
          />
          <PasswordInput
            name="confirmPassword"
            label={t('accountCreatePage.repeatPassword')}
            placeholder={t('accountCreatePage.repeatPassword')}
          />
        </Container>
        {/* Data for prescription */}
        <Container className={classes.container}>
          <FormSeparator label={t('accountSettingsPage.form.dataForPrescription')} />
          <MaskedInput
            label={t('accountSettingsPage.form.pesel.label')}
            placeholder="00000000000"
            name="pesel"
            mask={peselMask}
          />
        </Container>
        {/* Address */}
        <Container className={classes.container}>
          <FormSeparator label={t('accountSettingsPage.form.address')} />
          <FormTextField label={t('accountSettingsPage.form.street.label')} name="street" type="text" />
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField
                inputMode="numeric"
                label={t('accountSettingsPage.form.houseNumber.label')}
                name="houseNumber"
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                label={t('accountSettingsPage.form.apprtNumber.label')}
                name="apartmentNumber"
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={8}>
              <FormTextField label={t('accountSettingsPage.form.city.label')} name="city" type="text" />
            </Grid>
            <Grid item xs={12} md={4}>
              <MaskedInput
                label={t('accountSettingsPage.form.postalCode.label')}
                placeholder="00-999"
                name="zip"
                type="text"
                mask={zipMask}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Select options={voivodeshipOptions} name="voivodeship" label={t('accountSettingsPage.form.voivodeship')} />
          </Grid>
          <Grid className={classes.checkbox} container alignItems="center" direction="column">
            <Checkbox
              name="termsAndConditions"
              label={
                <Trans
                  i18nKey="surveyPage.contactDataForm.form.termsAndConditions"
                  components={{ lnk: <LinkText className={classes.linkText} to={RoutesDefinition.terms} /> }}
                />
              }
            />
          </Grid>
        </Container>
        <Container className={classes.submitButton}>
          <Button color="primary" fullWidth variant="contained" type="submit" disabled={isProcessing}>
            {t('accountCreatePage.submitButton')}
          </Button>
        </Container>
      </form>
    </FormProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%'
  },
  container: {
    marginBottom: '2rem'
  },
  checkbox: {
    marginTop: '2rem'
  },
  linkText: {
    color: 'inherit'
  },
  submitButton: {
    marginTop: theme.spacing(1),
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))
