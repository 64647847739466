import {
  Select as MuiSelect,
  MenuItem,
  SelectProps as MuiSelectProps,
  makeStyles,
  InputLabel,
  FormHelperText
} from '@material-ui/core'
import { ChangeEvent } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { DropdownIcon } from './DropdownIcon'
import { FormErrorMessage } from '../Form/FormErrorMessage'

interface SelectProps extends MuiSelectProps {
  name: string
  options: string[]
  label: string
}

export type SelectChangeType = ChangeEvent<{ name?: string | undefined; value: unknown }>

export const Select = ({ children, options, label, name, defaultValue, ...rest }: SelectProps) => {
  const classes = useStyle()

  const {
    field: { onChange, value, ...restField },
    fieldState: { error }
  } = useController({
    name,
    defaultValue
  })

  const {
    formState: { errors }
  } = useFormContext()

  const handleChange = (event: SelectChangeType) => {
    onChange(event.target.value)
  }

  // TODO: REFACTOR!!!
  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <MuiSelect
        defaultValue=""
        error={!!error}
        onChange={handleChange}
        IconComponent={() => <DropdownIcon />}
        {...restField}
        value={options.find((o) => o === value)}
        className={classes.select}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
        {children}
      </MuiSelect>
      {error && (
        <FormHelperText className={classes.errorMessage}>
          <FormErrorMessage errors={errors} name={name} />
        </FormHelperText>
      )}
    </>
  )
}

const useStyle = makeStyles((theme) => ({
  label: {
    zIndex: 0,
    position: 'relative',
    transform: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    letterSpacing: '0.0125rem',
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  },
  select: {
    padding: '1rem 0 0',
    width: '100%',
    '& > .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(4),
      borderRadius: '5.25rem',
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      width: '100%',
      height: '3rem',
      fontSize: '1rem',
      boxShadow: '0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.02)',
      backgroundColor: theme.customColors.white
    },
    '&::before, &::after': {
      display: 'none'
    },
    '& > .MuiSvgIcon-root': {
      right: theme.spacing(2),
      top: 'unset'
    }
  },
  errorMessage: {
    marginTop: '0.25rem',
    marginLeft: '14px',
    color: '#f44336'
  }
}))
