import { z } from 'zod'

export const promoCodeSchema = z.object({
  promoCode: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .min(1, { message: 'errors.required' })
        .min(3, { message: 'errors.codeTooShort' })
        .max(20)
        .safeParse(val)

      if (val && !fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    })
})

export type PromoCodeFormData = z.infer<typeof promoCodeSchema>
