import { Link, LinkProps } from 'react-router-dom'

export const LinkText = ({ to, children, ...rest }: React.PropsWithChildren<LinkProps>) => {
  return (
    <Link {...rest} to={to || ''}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <span>{children}</span>
    </Link>
  )
}
