import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/pl'
import { DAY_MONTH } from 'common/constants'

interface ExtendingVisitProps {
  date: dayjs.Dayjs
}

export const ExtendingVisit = ({ date }: ExtendingVisitProps) => {
  const { t } = useTranslation()

  return (
    <>
      {t('summary.renewPrescription')} <span>{date.locale('pl').format(DAY_MONTH).toString()}</span>
    </>
  )
}
