import { ChangeEvent, ReactNode } from 'react'
import { makeStyles, FormHelperText } from '@material-ui/core'
import { useController, useFormContext } from 'react-hook-form'

import { CheckboxBase } from './CheckboxBase'

import { FormErrorMessage } from '../Form/FormErrorMessage'

interface CheckboxProps {
  name: string
  label?: ReactNode
  className?: string
}

export const Checkbox = ({ name, label, className = '' }: CheckboxProps) => {
  const classes = useStyle()

  const {
    formState: { errors }
  } = useFormContext()

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name
  })

  const handleChange = (_: ChangeEvent<Record<string, unknown>>, checked: boolean) => {
    onChange(checked)
  }

  const isAnyError = !!error

  return (
    <>
      <CheckboxBase error={isAnyError} value={value} onChange={handleChange} className={className} label={label} />

      {isAnyError && (
        <FormHelperText error={isAnyError} className={classes.errorMessage}>
          <FormErrorMessage errors={errors} name={name} />
        </FormHelperText>
      )}
    </>
  )
}

const useStyle = makeStyles(() => ({
  errorMessage: {
    '&.MuiFormHelperText-root.Mui-error': {
      marginTop: '0.25rem'
    }
  }
}))
