import { lazy, useEffect } from 'react'
import TagManager from 'react-gtm-module'

function App(): React.ReactElement {
  const PublicRouting = lazy(() => import('./routing/GuestRouting'))
  const ProtectedRouting = lazy(() => import('./routing/ProtectedRouting'))

  const tagManagerArgs = {
    gtmId: 'GTM-WWQ3HLQ'
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PublicRouting />
      <ProtectedRouting />
    </>
  )
}

export default App
