import { useState, createContext, FC, Dispatch } from 'react'

import { COMPLETED_STEPS_STORAGE_KEY } from 'common/constants'
import { useLocalStorageState } from 'common/hooks'

interface CompletedStepData {
  id: number
  userChoice: string
  questionTitle: string
}
interface CompletedSteps {
  [id: string]: CompletedStepsData
}
interface CompletedStepsData {
  questionTitle: string
  userChoice: string
}

export interface SurveyState {
  selectedCondition: string | undefined
  lastActiveStep: number
  completedSteps: CompletedSteps
  setLastActiveStep: Dispatch<number>
  pushCompletedStep: (stepData: CompletedStepData) => void
  clearSteps: () => void
}

export const surveyStateDefaultValue: SurveyState = {
  selectedCondition: undefined,
  completedSteps: {},
  lastActiveStep: 0,
  setLastActiveStep: () => {},
  pushCompletedStep: () => {},
  clearSteps: () => {}
}

export const SurveyContext = createContext(surveyStateDefaultValue)

export const SurveyProvider: FC = ({ children }) => {
  const [completedSteps, setCompletedSteps] = useLocalStorageState(
    COMPLETED_STEPS_STORAGE_KEY,
    surveyStateDefaultValue.completedSteps
  )
  // Here we assume that first question is always about user condition.
  // If it changes the functionality needs to be adjusted
  const selectedCondition = (Object.values(completedSteps)[0] as CompletedStepsData)?.userChoice || undefined

  const storedLastStep = Object.entries(completedSteps).length
  const [lastActiveStep, setLastActiveStep] = useState(storedLastStep || surveyStateDefaultValue.lastActiveStep)

  function clearSteps() {
    setCompletedSteps({})
  }

  function pushCompletedStep({ id, questionTitle, userChoice }: CompletedStepData) {
    setCompletedSteps({
      ...completedSteps,
      [id]: {
        questionTitle,
        userChoice
      }
    })
  }
  return (
    <SurveyContext.Provider
      value={{
        selectedCondition,
        lastActiveStep,
        completedSteps,
        setLastActiveStep,
        pushCompletedStep,
        clearSteps
      }}
    >
      {children}
    </SurveyContext.Provider>
  )
}
