import { IconButton, makeStyles, Theme } from '@material-ui/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormTextField } from '../Form'
import iconEye from '../../icons/open_eye_icon.svg'
import iconEyeClosed from '../../icons/closed_eye_icon.svg'

interface PasswordInputProps {
  name: string
  placeholder: string
  label?: string
}

export const PasswordInput = ({ name, placeholder, label }: PasswordInputProps) => {
  const [visibility, setVisibility] = useState(false)

  const classes = useStyle({ hasLabel: !!label })
  const { t } = useTranslation()

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  return (
    <div className={classes.box}>
      <FormTextField name={name} type={visibility ? 'text' : 'password'} placeholder={placeholder} label={label} />
      <IconButton
        aria-label={visibility ? t('passwordInput.ariaLabelVisible') : t('passwordInput.ariaLabelHidden')}
        onClick={toggleVisibility}
        edge="end"
      >
        {visibility ? (
          <img src={iconEyeClosed} alt={t('passwordInput.visibleAlt')} />
        ) : (
          <img src={iconEye} alt={t('passwordInput.hiddenAlt')} />
        )}
      </IconButton>
    </div>
  )
}

const useStyle = makeStyles<Theme, { hasLabel: boolean }>(() => ({
  box: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& > button': {
      position: 'absolute',
      zIndex: 2,
      top: (props) => (props.hasLabel ? '2.9rem' : '1.15rem'),
      margin: '0.25rem 0',
      right: '5%'
    },
    '& .makeStyles-inputElement-14 > input': {
      padding: '0.625rem 3rem 0.625rem 1rem'
    }
  }
}))
