import { Typography, makeStyles } from '@material-ui/core'
import { TFuncKey, Trans } from 'react-i18next'

interface TwoColorHeaderProps {
  translationPath: string
  className?: string
}

export const TwoColorHeader = ({ translationPath, className = '' }: TwoColorHeaderProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Typography variant="h1" className={className}>
      <Trans i18nKey={translationPath as TFuncKey}>
        Rozpocznij <span className={classes.title}>terapię</span>
      </Trans>
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main
  }
}))
