import { Chip, makeStyles } from '@material-ui/core'

interface AnswerPillProps extends AnswerPillType {
  toggleOption: () => void
}

export interface AnswerPillType {
  label: string | JSX.Element
  isChecked?: boolean
}

export const AnswerPill = ({ label, isChecked, toggleOption }: AnswerPillProps): JSX.Element => {
  const classes = useStyle()

  return (
    <Chip
      className={classes.pill}
      color="secondary"
      label={label}
      variant={isChecked ? 'default' : 'outlined'}
      onClick={toggleOption}
    />
  )
}

const useStyle = makeStyles((theme) => ({
  pill: {
    fontSize: 'clamp(1.1em, 1.25vw, 1.5em)',
    padding: '0.7rem 1.5rem',
    borderRadius: '5rem',
    border: `1px solid ${theme.palette.secondary.main}`,
    height: 'unset',
    '& > span': {
      whiteSpace: 'initial',
      padding: 0
    }
  }
}))
