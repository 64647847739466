import ReactMaskedInput from 'react-text-mask'
import { InputProps, FormTextField } from 'common/components'

interface MaskedInputProps extends InputProps {
  mask: Array<string | RegExp> | false
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void
  mask: Array<string | RegExp> | false
}

function TextMaskCustom({ inputRef, mask, ...rest }: TextMaskCustomProps) {
  return (
    <ReactMaskedInput
      {...rest}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask || false}
    />
  )
}

export const MaskedInput = ({ mask, ...rest }: MaskedInputProps) => {
  return (
    <FormTextField
      {...rest}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: TextMaskCustom as any,
        inputProps: {
          mask
        }
      }}
    />
  )
}
