import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useMobileScreenSizeDetector } from 'common/hooks'
import { DesktopProcessSVG, MobileProcessSVG } from './HiW'

export const HowItWorks = (): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isMobile = useMobileScreenSizeDetector()

  return (
    <Box
      id="howItWorks"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={classes.sectionWrapper}
    >
      <Typography variant="h2">{t('landingPage.howItWorksSection.header')}</Typography>
      <Typography variant="body1" className={classes.highlightedText}>
        {t('landingPage.howItWorksSection.paragraph1')}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {t('landingPage.howItWorksSection.paragraph2')}
      </Typography>
      <Box className={classes.imageContainer}>{isMobile ? <MobileProcessSVG /> : <DesktopProcessSVG />}</Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'start',

      '&': {
        marginTop: '10vh'
      }
    }
  },
  imageContainer: {
    margin: `${theme.spacing(3)} 0`,
    width: '100%',

    '& > svg': {
      width: '100%',
      maxHeight: '90%'
    }
  },
  text: {
    margin: '0.3rem 0',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  highlightedText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    margin: '1.5rem 0.3rem 0 0'
  }
}))
