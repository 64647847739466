import { FC } from 'react'
import { SurveyProvider, UserContextProvider, SessionContextProvider } from 'state'

export const StateWrapper: FC = ({ children }) => (
  <UserContextProvider>
    <SessionContextProvider>
      <SurveyProvider>{children}</SurveyProvider>
    </SessionContextProvider>
  </UserContextProvider>
)
