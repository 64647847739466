import { z } from 'zod'

export const summaryConfirmation = z.object({
  notAddictedConfirmation: z.boolean().superRefine((val, ctx) => {
    if (!val) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.notAddictedConfirmationRequired' })
    }
  })
})

export type SummaryConfirmationData = z.infer<typeof summaryConfirmation>
