import { z } from 'zod'

export const patientPersonalInfoSchema = {
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  email: z.string(),
  pesel: z.string(),
  birthDate: z.string(),
  gender: z.string(),
  address: z.object({
    zip: z.string(),
    city: z.string(),
    street: z.string(),
    houseNumber: z.string(),
    apartmentNumber: z.optional(z.string())
  })
}
