import { z } from 'zod'

export const openEndedQuestion = z.object({
  answer: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.required')
        .min(10, { message: 'errors.answerTooShort' })
        .safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    })
})

export type OpenEndedQuestionData = z.infer<typeof openEndedQuestion>
