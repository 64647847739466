import { apiClient } from 'common/utils/api'

interface UpdateAccountPasswordData {
  token: string
  userId: string
  password: string
  confirmPassword: string
}

export const updatePasswordRequest = async (requestData: UpdateAccountPasswordData): Promise<void> => {
  await apiClient(requestData.token)
    .put(`Accounts/${requestData.userId}`, {
      json: { password: requestData.password, confirmPassword: requestData.confirmPassword }
    })
    .json()
}
