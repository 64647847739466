export const eventTypes = {
  typy: [
    {
      id: 7,
      name: 'Długa, konsultacja dla nowych klientów.',
      duration: 15,
      price: 250
    },
    {
      id: 8,
      name: 'Długa, konsultacja dla obecnych klientów.',
      duration: 15,
      price: 250
    },
    {
      id: 9,
      name: 'Krótka, recepta dla obecnych klientów.',
      duration: 5,
      price: 150
    }
  ]
}
