import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Grid } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { useMobileScreenSizeDetector } from 'common/hooks'
import leafLogo from '../images/leafLogo.svg'

export const IsLegal = (): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isMobile = useMobileScreenSizeDetector()
  const mobileBox = (
    <Box className={classes.mobileBox}>
      <img src={leafLogo} alt="IsLegalImage" />
    </Box>
  )

  return (
    <Grid id="isLegal" container direction="row" item className={classes.sectionWrapper}>
      <Grid className={classes.content} container xs={12} md={6} direction="column" alignItems="center" item>
        <Typography variant="h2">{t('landingPage.legalSection.header')}</Typography>
        <Typography className={classes.hiddenOnMobile} variant="body1">
          <Trans i18nKey="landingPage.legalSection.startingParagraph1" components={{ b: <b /> }} />{' '}
          {t('landingPage.legalSection.startingParagraph2')}
        </Typography>
        {isMobile && (
          <>
            <Box className={classes.mobileParagraph}>
              {mobileBox}
              <Typography variant="body1">
                <Trans i18nKey="landingPage.legalSection.startingParagraph1" components={{ b: <b /> }} />
              </Typography>
            </Box>
            <Typography variant="body1">
              <Trans i18nKey="landingPage.legalSection.startingParagraph2" />
            </Typography>
          </>
        )}
        <Typography variant="body1">
          <Trans i18nKey="landingPage.legalSection.paragraph2" components={{ b: <b /> }} />
        </Typography>
        <Typography variant="body1">
          <Trans i18nKey="landingPage.legalSection.paragraph3" components={{ b: <b /> }} />
        </Typography>
      </Grid>
      <Grid container sm={6} alignItems="center" direction="column" item>
        <Box className={classes.imageContainer}>
          <img src={leafLogo} alt="IsLegalImage" />
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    margin: '0 0 40vh 0',
    padding: `20vh ${theme.spacing(7.5)} 0`,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      margin: '10vh 0 20vh 0'
    }
  },
  content: {
    '& > h2': {
      width: '100%',
      textAlign: 'left',

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginBottom: theme.spacing(4)
      }
    }
  },
  hiddenOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileParagraph: {
    display: 'flex',

    '& > p': {
      margin: 0,
      padding: `${theme.spacing(2)} 0`
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.customColors.white}`,
    boxShadow: `0px 2px 11px 0px ${theme.customColors.lightBlack}`,
    height: '100%',
    width: '70%',
    borderRadius: '2rem',
    padding: theme.spacing(4),

    '& > img': {
      width: '90%'
    },

    [theme.breakpoints.down('md')]: {
      width: '80%'
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.customColors.white}`,
    boxShadow: `0px 2px 11px 0px ${theme.customColors.lightBlack}`,
    borderRadius: '1rem',
    height: '100%',
    margin: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
    padding: theme.spacing(2),

    '& img': {
      width: '90%'
    }
  }
}))
