import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Button } from 'common/components'

export interface DashboardTileProps {
  icon: string
  alt: string
  price: string
  title: string
  description: string
  handleButtonClick: () => void
}

export const DashboardTile = ({ icon, alt, price, handleButtonClick, title, description }: DashboardTileProps) => {
  const classes = useStyle()
  const { t } = useTranslation()

  return (
    <Grid item sm={5} className={classes.tile}>
      <img src={icon} alt={alt} />
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      <span>{price}</span>
      <Button type="button" handleButtonClick={handleButtonClick} variant="outlined" color="primary" fullWidth>
        {t('accountDashboardPage.button')}
      </Button>
    </Grid>
  )
}

const useStyle = makeStyles((theme) => ({
  tile: {
    padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    boxShadow: theme.boxShadow.solidGrey,
    borderRadius: '1.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      margin: `0 0 ${theme.spacing(4)} 0`
    },

    '& > img': {
      height: '17.5%'
    },

    '& > h3': {
      fontWeight: 700,
      margin: `${theme.spacing(2)} 0`,
      fontSize: '1.5rem',
      textAlign: 'center'
    },

    '& > p': {
      textAlign: 'center',
      fontSize: '1rem',
      margin: 0,
      lineHeight: '1.25rem'
    },

    '& > span': {
      color: theme.palette.primary.main,
      fontSize: '2rem',
      fontWeight: 700,
      margin: `${theme.spacing(2)} 0`
    },

    '& > button': {
      width: '70%'
    }
  }
}))
