import { z } from 'zod'
import { errorUtil } from 'zod/lib/helpers/errorUtil'
import { validatePesel } from 'common/utils/validatePesel'
import { postalCodeRegExp } from 'common/constants'

export const createFormSchema = z
  .object({
    password: z
      .string()
      .transform((val) => val.trim())
      .superRefine((val, ctx) => {
        const fieldValidationResult = z
          .string()
          .nonempty('errors.passwordRequired')
          .min(8, { message: 'errors.incorrectPassword' })
          .safeParse(val)

        const includesLowerCase = /[a-z]/.test(val)
        const includesUpperCase = /[A-Z]/.test(val)
        const includesDigit = /[\d]/.test(val)
        const includesSpecialChar = /[^\d\w\s]/.test(val)

        if (!includesLowerCase || !includesUpperCase || !includesDigit || !includesSpecialChar) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'errors.incorrectPassword'
          })
        }
        if (!fieldValidationResult.success) {
          ctx.addIssue(fieldValidationResult.error.issues[0])
        }
      }),
    confirmPassword: z
      .string()
      .transform((val) => val.trim())
      .superRefine((val, ctx) => {
        const fieldValidationResult = z
          .string()
          .nonempty('errors.passwordRequired')
          .min(6, { message: 'errors.incorrectPassword' })
          .safeParse(val)

        if (!fieldValidationResult.success) {
          ctx.addIssue(fieldValidationResult.error.issues[0])
        }
      }),
    pesel: z
      .string()
      .transform((val) => val.trim())
      .superRefine((val, ctx) => {
        const fieldValidationResult = z.string().safeParse(val)

        if (!fieldValidationResult.success) {
          ctx.addIssue(fieldValidationResult.error.issues[0])
        }

        if (!validatePesel(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errorUtil.toString('errors.invalidPesel')
          })
        }
      }),
    street: z.string().nonempty('errors.required').max(64),
    houseNumber: z.string().nonempty('errors.required').max(8),
    apartmentNumber: z.optional(z.string().refine((val) => Number(val) > 0 || val === '')),
    city: z.string().nonempty('errors.required').max(64),
    zip: z.string().nonempty('errors.required').regex(postalCodeRegExp),
    voivodeship: z.string().nonempty('errors.required'),
    termsAndConditions: z.boolean().superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.termsAndConditionsRequired' })
      }
    })
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'errors.passwordsDontMatch',
    path: ['confirmPassword']
  })

export type CreateFormData = z.infer<typeof createFormSchema>
