import { z } from 'zod'
import { errorUtil } from 'zod/lib/helpers/errorUtil'
import { validatePesel } from 'common/utils/validatePesel'
import { peselRegExp, phoneRegExp, postalCodeRegExp } from 'common/constants'

export const settingsFormSchema = z.object({
  firstName: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z.string().nonempty('errors.required').min(3).safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    }),
  lastName: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z.string().nonempty('errors.required').min(3).safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    }),
  email: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.emailRequired')
        .email('errors.incorrectEmail')
        .safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    }),
  phoneNumber: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z.string().nonempty('errors.required').regex(phoneRegExp).safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'errors.incorrectPhoneNumber' })
      }
    }),
  pesel: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.peselRequired')
        .regex(peselRegExp, 'errors.invalidPesel')
        .safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }

      if (!validatePesel(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errorUtil.toString('errors.invalidPesel')
        })
      }
    }),
  street: z.string().nonempty('errors.required').max(64),
  houseNumber: z.string().nonempty('errors.required').max(8),
  apartmentNumber: z.optional(z.string().refine((val) => Number(val) > 0 || val === '')),
  voivodeship: z.string().nonempty('errors.required'),
  city: z.string().nonempty('errors.required').max(64),
  postalCode: z.string().nonempty('errors.required').regex(postalCodeRegExp)
})

export const setNewPasswordFormSchema = z
  .object({
    newPassword: z
      .string()
      .transform((val) => val.trim())
      .superRefine((val, ctx) => {
        const fieldValidationResult = z
          .string()
          .nonempty('errors.passwordRequired')
          .min(8, { message: 'errors.incorrectPassword' })
          .safeParse(val)

        const includesLowerCase = /[a-z]/.test(val)
        const includesUpperCase = /[A-Z]/.test(val)
        const includesDigit = /[\d]/.test(val)
        const includesSpecialChar = /[^\d\w\s]/.test(val)

        if (!includesLowerCase || !includesUpperCase || !includesDigit || !includesSpecialChar) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'errors.incorrectPassword'
          })
        }

        if (!/[a-z]/.test(val) || !/[A-Z]/.test(val) || !/[\d]/.test(val) || !/[^\d\w\s]/.test(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'errors.incorrectPassword'
          })
        }

        if (!fieldValidationResult.success) {
          ctx.addIssue(fieldValidationResult.error.issues[0])
        }
      }),
    repeatPassword: z
      .string()
      .transform((val) => val.trim())
      .superRefine((val, ctx) => {
        const fieldValidationResult = z
          .string()
          .nonempty('errors.passwordRequired')
          .min(6, { message: 'errors.incorrectPassword' })
          .safeParse(val)

        if (!fieldValidationResult.success) {
          ctx.addIssue(fieldValidationResult.error.issues[0])
        }
      })
  })
  .refine((data) => data.newPassword === data.repeatPassword, {
    message: 'errors.passwordsDontMatch',
    path: ['repeatPassword']
  })

export type AccountSettingsFormData = z.infer<typeof settingsFormSchema>
export type SetNewPasswordFormData = z.infer<typeof setNewPasswordFormSchema>
