import { useEffect, useState } from 'react'

export const useSticky = (offsetValue: number) => {
  const [isSticky, setIsSticky] = useState(false)

  const handleScroll = () => {
    const offset = window.scrollY
    setIsSticky(offset > offsetValue)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isSticky
}
