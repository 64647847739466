import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'

interface FullScreenLoaderProps {
  open: boolean
}

export const FullScreenLoader = ({ open }: FullScreenLoaderProps) => {
  const classes = useStyle()
  return (
    <Backdrop open={open} className={classes.root}>
      <CircularProgress size={100} />
    </Backdrop>
  )
}

const useStyle = makeStyles((theme) => ({
  root: {
    zIndex: 10,
    background: theme.customColors.white,
    position: 'fixed'
  }
}))
