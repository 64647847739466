import { useState } from 'react'

const preparePillLabels = (options: string[]) => {
  return options.map((option) => ({
    label: option,
    isChecked: false
  }))
}

export function useMultipleChoiceState(inputOptions: string[]) {
  const freshOptions = preparePillLabels(inputOptions)
  const [options, setOptions] = useState(freshOptions)

  const chooseOption = (index: number) => {
    const newOptions = [...freshOptions]
    newOptions[index].isChecked = !options[index].isChecked
    setOptions(newOptions)
  }

  const clearOptions = () => {
    const newOptions = [...freshOptions]
    setOptions(newOptions)
  }

  const selectedOption = options.find((option) => option.isChecked)

  return {
    pillChoice: selectedOption?.label,
    options,
    chooseOption,
    clearPills: clearOptions
  }
}
