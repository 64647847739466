import { refreshTokenData, revokeToken } from 'features'
import { FC, createContext, useContext, useCallback, useReducer, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useUser } from './userState'

interface LoginData {
  token: string
  rememberMe: boolean
}
interface SessionContextShape {
  token: string | null
  isLogged: boolean
  isBootstrapFinished: boolean
  login: ({ token, rememberMe }: LoginData) => void
  logout: () => void
}

const sessionKey = process.env.REACT_APP_SESSION_COOKIE_NAME || 'session'

type ActionTypes = 'logout' | 'login' | 'bootstrapStatus'

interface SessionAction {
  type: ActionTypes
  params?: { token?: string | null; bootstrapStatus?: boolean }
}
interface SessionState {
  isLogged: boolean
  isBootstrapFinished: boolean
  token: string | null
}

const sessionReducer = (state: SessionState, action: SessionAction): SessionState => {
  switch (action.type) {
    case 'logout':
      return {
        ...state,
        token: null,
        isLogged: false
      }
    case 'login':
      return {
        ...state,
        isLogged: !!action.params?.token,
        token: action.params?.token || null,
        isBootstrapFinished: true
      }
    case 'bootstrapStatus':
      return {
        ...state,
        isBootstrapFinished: action.params?.bootstrapStatus || true
      }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

const SessionContext = createContext<SessionContextShape | undefined>(undefined)

export const SessionContextProvider: FC = ({ children }) => {
  const [sessionState, sessionDispatcher] = useReducer(sessionReducer, {
    isLogged: false,
    token: null,
    isBootstrapFinished: false
  })
  const { updateUserState } = useUser()

  const queryClient = useQueryClient()

  const history = useHistory()

  const handleTokenRefresh = useCallback(async () => {
    try {
      const response = await refreshTokenData()
      updateUserState(response)
      const decodedToken = parseJWT(response.jwtToken)
      localStorage.setItem(sessionKey, response.jwtToken)
      dispatchTokenExpiration(decodedToken.exp)
      sessionDispatcher({ type: 'login', params: { token: response.jwtToken } })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      sessionDispatcher({ type: 'bootstrapStatus', params: { bootstrapStatus: true } })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem(sessionKey)
    if (tokenFromStorage) {
      handleTokenRefresh()
    } else {
      sessionDispatcher({ type: 'bootstrapStatus', params: { bootstrapStatus: true } })
    }
  }, [handleTokenRefresh])

  const checkForToken = useCallback(() => {
    const tokenFromStorage = localStorage.getItem(sessionKey)
    if (!tokenFromStorage) {
      sessionDispatcher({ type: 'logout' })
      queryClient.clear()
    }
  }, [queryClient])

  useEffect(() => {
    window.addEventListener('storage', checkForToken)

    return () => {
      window.removeEventListener('storage', checkForToken)
    }
  }, [checkForToken])

  const loginHandler = ({ token, rememberMe }: LoginData) => {
    if (rememberMe) {
      localStorage.setItem(sessionKey, token)
    } else {
      sessionStorage.setItem(sessionKey, token)
    }
    const decodedToken = parseJWT(token)
    dispatchTokenExpiration(decodedToken.exp)
    sessionDispatcher({ type: 'login', params: { token } })
  }

  const dispatchTokenExpiration = (expirationTime: number) => {
    const expirationDate = new Date(expirationTime * 1000)
    const timeToExpirationAfterDateConversion = expirationDate.getTime() - new Date().getTime()

    const MIN_IN_MS = 1000 * 60
    const minBeforeExpirationTime = timeToExpirationAfterDateConversion - 1 * MIN_IN_MS

    setTimeout(() => {
      handleTokenRefresh()
    }, minBeforeExpirationTime)
  }

  const logoutHandler = async () => {
    const token = localStorage.getItem(sessionKey)
    if (token) {
      await revokeToken(token)
    }
    localStorage.removeItem(sessionKey)
    sessionStorage.removeItem(sessionKey)
    document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
    sessionDispatcher({ type: 'logout' })
    queryClient.clear()
    history.push('/')
  }

  const authContextValue: SessionContextShape = {
    ...sessionState,
    login: loginHandler,
    logout: logoutHandler
  }

  return <SessionContext.Provider value={authContextValue}>{children}</SessionContext.Provider>
}

export function useSession() {
  const context = useContext(SessionContext)
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider')
  }
  return context
}

const parseJWT = (jwt: string) => {
  if (jwt !== undefined) {
    try {
      return JSON.parse(atob(jwt.split('.')[1]))
    } catch (e) {
      return undefined
    }
  }
  return undefined
}
