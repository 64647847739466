import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { StateWrapper } from 'state'

import 'common/utils/i18n'

import { FullScreenLoader, SnackbarWrapper, Theme } from 'common/components'

import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false }
  }
})

ReactDOM.render(
  <StrictMode>
    <Theme>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Suspense fallback={<FullScreenLoader open />}>
            <StateWrapper>
              <SnackbarWrapper>
                <App />
              </SnackbarWrapper>
            </StateWrapper>
          </Suspense>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Theme>
  </StrictMode>,
  document.getElementById('root')
)
