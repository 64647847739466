import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Button } from 'common/components'

interface HintPageContentProps {
  header: string
  greenText: string
  message: string
  handleBackClick: () => void
  handleExitClick: () => void
}

export const HintPageContent = ({
  header,
  greenText,
  message,
  handleBackClick,
  handleExitClick
}: HintPageContentProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Container disableGutters maxWidth={false} className={classes.wrapper}>
      <Grid className={classes.content} item xs={12} sm={8} md={5}>
        <Typography variant="h1">
          {header}
          {'\n'}
          <span>{greenText}</span>
        </Typography>
        <Typography variant="body1">{message}</Typography>
        <Box className={classes.subBox}>
          <Button variant="contained" color="primary" fullWidth handleButtonClick={handleBackClick} type="button">
            {t('surveyPage.hintPage.backButton')}
          </Button>
          <Button variant="outlined" color="secondary" fullWidth handleButtonClick={handleExitClick} type="button">
            {t('surveyPage.hintPage.exitButton')}
          </Button>
        </Box>
      </Grid>
    </Container>
  )
}
const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.customColors.backgroundGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '93.15vh'
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}`,
      flexBasis: 'unset'
    },
    '& > h1': {
      color: theme.customColors.white,
      textAlign: 'center',

      '& > span': {
        color: theme.palette.primary.main
      }
    },
    '& > p': {
      color: theme.customColors.white,
      textAlign: 'center',
      margin: `${theme.spacing(4)} 0 ${theme.spacing(6)}`,

      [theme.breakpoints.down('sm')]: {
        margin: `${theme.spacing(2)} 0 ${theme.spacing(4)}`
      }
    }
  },
  subBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(4)}`,

    [theme.breakpoints.down('sm')]: {
      padding: 0
    },

    '& > button': {
      margin: `0 0 ${theme.spacing(4)}`,

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2)
      }
    }
  }
}))
