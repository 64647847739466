import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import logo from 'layouts/components/Menu/images/logo.svg'
import { ContactFormData } from 'features/landingSections/types'
import { SubmitHandler } from 'react-hook-form'
import { ContactForm } from './ContactForm'

interface ContactProps {
  handleSubmit: SubmitHandler<ContactFormData>
  isSubmitError: boolean
  isProcessing?: boolean
  isSuccess?: boolean
}

export const Contact = ({ handleSubmit, isSubmitError, isProcessing, isSuccess }: ContactProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container direction="row" item className={classes.sectionWrapper}>
      <Grid item xs={12} md={5}>
        <Grid container direction="column" alignItems="flex-start" className={classes.contactColumn}>
          <img src={logo} alt="Drugly Logo" />
          <Typography variant="h2">{t('landingPage.contactSection.header')}</Typography>
          <Typography className={classes.description} variant="h3">
            {t('landingPage.contactSection.description')}
          </Typography>
          <Box className={classes.contactData}>
            <a className={classes.link} href="mailto:info@drugly.pl">
              {t('landingPage.contactSection.email')}
            </a>
            <a className={classes.link} href="tel:506-004-902">
              {t('landingPage.contactSection.phone1')}
            </a>
            <a className={classes.link} href="tel:506-004-602">
              {t('landingPage.contactSection.phone2')}
            </a>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7}>
        <ContactForm
          isSuccess={isSuccess}
          isProcessing={isProcessing}
          onSubmit={handleSubmit}
          isSubmitError={isSubmitError}
        />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    minHeight: '79vh',
    padding: `0 ${theme.spacing(7.5)} 20vh`,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,

      '& > div': {
        width: '100%'
      }
    }
  },
  contactColumn: {
    '& img': {
      marginBottom: theme.spacing(2)
    }
  },
  description: {
    margin: `${theme.spacing(2)} 0`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  contactData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '1.25rem'
  }
}))
