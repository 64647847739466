import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RoutesDefinition } from 'routing/constants/RoutesDefinition'
import { FormTextField, DualColorChoiceLink, Button } from 'common/components'
import { ForgetPasswordData, forgetPasswordSchema } from 'features/forgetPassword/types'
import { Grid, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

interface ForgetPasswordFormProps {
  onSubmit: SubmitHandler<ForgetPasswordData>
  isProcessing?: boolean
  isSuccess?: boolean
}

export function ForgetPasswordForm({ onSubmit, isProcessing, isSuccess }: ForgetPasswordFormProps): JSX.Element {
  const classes = useStyle()
  const { t } = useTranslation()
  const history = useHistory()

  const formMethods = useForm<ForgetPasswordData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(forgetPasswordSchema),
    defaultValues: {
      email: ''
    }
  })

  useEffect(() => {
    if (isSuccess) {
      history.push(RoutesDefinition.resetPasswordSuccess)
    }
  }, [history, isSuccess])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className={classes.formWrapper}>
        <FormTextField
          name="email"
          type="text"
          label={t('forgetPassword.form.email.label')}
          placeholder={t('forgetPassword.form.email.placeholder')}
        />
        <Grid container justifyContent="flex-end">
          <DualColorChoiceLink routingPath={RoutesDefinition.authLogin} translationPath="forgetPassword.form.login" />
        </Grid>
        <Grid className={classes.buttonWrapper}>
          <Button type="submit" color="primary" fullWidth variant="contained" disabled={isProcessing}>
            {t('forgetPassword.form.submitButton')}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  )
}

const useStyle = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '70%',

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },

    '& > div': {
      padding: `0 0 ${theme.spacing(2)} 0`,

      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  },
  buttonWrapper: {
    width: '90%'
  }
}))
