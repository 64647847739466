import { makeStyles } from '@material-ui/core'

export const useNavigationElementsStyle = makeStyles((theme) => ({
  basicLink: {
    fontSize: 'clamp(0.5rem, 2vw, 1rem)',
    letterSpacing: '0.019rem',
    lineHeight: 1.33,
    margin: `0 0 0 ${theme.spacing(14)}`,
    width: '100%',
    color: theme.customColors.white,
    textDecoration: 'none',
    background: 'transparent',
    border: '0',
    padding: '0',

    [theme.breakpoints.down('md')]: {
      margin: `0 0 0 ${theme.spacing(4)}`
    },

    [theme.breakpoints.down('sm')]: {
      margin: `0 0 0 ${theme.spacing(2)}`
    },

    '&:hover, &.active': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  }
}))
