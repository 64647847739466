import { Grid, makeStyles, Typography } from '@material-ui/core'

interface OfferItemProps {
  name: string
  description: string
  price: string
}

export const OfferItem = ({ name, description, price }: OfferItemProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container className={classes.offerItem}>
      <Typography variant="body1">{name}</Typography>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="body1">{price}</Typography>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  offerItem: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.customColors.lightGrey}`
    },

    '& > p:first-of-type': {
      fontWeight: 700
    },

    '& > *': {
      width: '33%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      borderRight: `1px solid ${theme.customColors.lightGrey}`,
      margin: 0,
      padding: theme.spacing(2),

      '&:last-child': {
        borderRight: 'none'
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem'
      }
    }
  }
}))
