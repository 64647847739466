import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, makeStyles, Container } from '@material-ui/core'
import { FormSeparator, FormTextField, Button, PasswordInput, MaskedInput, Select } from 'common/components'
import { peselMask, phoneMask, zipMask } from 'common/constants'
import { PatientDetailsRequestResponse } from 'features'
import { useEffect } from 'react'
import { useForm, SubmitHandler, FormProvider, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { voivodeshipOptions } from 'common/constants/voivodeships'
import {
  SetNewPasswordFormData,
  setNewPasswordFormSchema,
  AccountSettingsFormData,
  settingsFormSchema
} from '../../types/accountSettingsForm'

interface AccountSettingsFormProps {
  settingsOnSubmit: SubmitHandler<AccountSettingsFormData>
  passwordOnSubmit: SubmitHandler<SetNewPasswordFormData>
  patientDetails: PatientDetailsRequestResponse | undefined
  isSubmitError: boolean
  isProcessing?: boolean
}

export const AccountSettingsForm = ({
  settingsOnSubmit,
  passwordOnSubmit,
  patientDetails
}: AccountSettingsFormProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()

  const settingsFormMethods = useForm<AccountSettingsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(settingsFormSchema),
    defaultValues: {
      ...patientDetails,
      phoneNumber: patientDetails?.phone,
      postalCode: patientDetails?.address.zip,
      street: patientDetails?.address.street,
      houseNumber: patientDetails?.address.houseNumber,
      city: patientDetails?.address.city,
      apartmentNumber: patientDetails?.address.apartmentNumber,
      voivodeship: voivodeshipOptions[0]
    }
  })

  const { isDirty } = useFormState({ control: settingsFormMethods.control })

  useEffect(() => {
    const parsedVoivodship =
      patientDetails && patientDetails.nfz
        ? voivodeshipOptions[Number.parseInt(patientDetails.nfz, 10) - 1]
        : voivodeshipOptions[0]

    settingsFormMethods.reset({
      ...patientDetails,
      phoneNumber: patientDetails?.phone,
      postalCode: patientDetails?.address.zip,
      street: patientDetails?.address.street,
      houseNumber: patientDetails?.address.houseNumber,
      city: patientDetails?.address.city,
      apartmentNumber: patientDetails?.address.apartmentNumber,
      voivodeship: parsedVoivodship
    })
  }, [patientDetails, settingsFormMethods])

  const passwordFormMethods = useForm<SetNewPasswordFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(setNewPasswordFormSchema)
  })

  const { isSubmitSuccessful: isPasswordUpdateSuccessful } = useFormState({ control: passwordFormMethods.control })

  useEffect(() => {
    passwordFormMethods.reset({
      newPassword: '',
      repeatPassword: ''
    })
  }, [isPasswordUpdateSuccessful, passwordFormMethods])

  return (
    <>
      <FormProvider {...settingsFormMethods}>
        <form className={classes.form} onSubmit={settingsFormMethods.handleSubmit(settingsOnSubmit)}>
          {/* Basic info */}
          <Container className={classes.container}>
            <FormSeparator label={t('accountSettingsPage.form.basicInfo')} />
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <FormTextField label={t('accountSettingsPage.form.firstName.label')} name="firstName" type="text" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField label={t('accountSettingsPage.form.lastName.label')} name="lastName" type="text" />
              </Grid>
            </Grid>
            <FormTextField
              label={t('accountSettingsPage.form.email.label')}
              placeholder="example@example.com"
              name="email"
              type="email"
            />
            <MaskedInput
              label={t('accountSettingsPage.form.phoneNumber.label')}
              placeholder="000 000 000"
              name="phoneNumber"
              type="text"
              mask={phoneMask}
            />
          </Container>
          {/* Data for prescription */}
          <Container className={classes.container}>
            <FormSeparator label={t('accountSettingsPage.form.dataForPrescription')} />
            <MaskedInput
              label={t('accountSettingsPage.form.pesel.label')}
              placeholder="00000000000"
              name="pesel"
              type="text"
              mask={peselMask}
            />
          </Container>
          {/* Address */}
          <Container className={classes.container}>
            <FormSeparator label={t('accountSettingsPage.form.address')} />
            <FormTextField label={t('accountSettingsPage.form.street.label')} name="street" type="text" />
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  inputMode="numeric"
                  label={t('accountSettingsPage.form.houseNumber.label')}
                  name="houseNumber"
                  type="string"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  label={t('accountSettingsPage.form.apprtNumber.label')}
                  name="apprtNumber"
                  type="number"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={8}>
                <FormTextField label={t('accountSettingsPage.form.city.label')} name="city" type="text" />
              </Grid>
              <Grid item xs={12} md={4}>
                <MaskedInput
                  label={t('accountSettingsPage.form.postalCode.label')}
                  placeholder="00-999"
                  name="postalCode"
                  type="text"
                  mask={zipMask}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Select
                options={voivodeshipOptions}
                name="voivodeship"
                label={t('accountSettingsPage.form.voivodeship')}
              />
            </Grid>
          </Container>
          <Container className={classes.submitButton}>
            <Button color="primary" fullWidth variant="contained" type="submit" disabled={!isDirty}>
              {t('accountSettingsPage.form.submitButton')}
            </Button>
          </Container>
        </form>
      </FormProvider>
      <FormProvider {...passwordFormMethods}>
        <form className={classes.form} onSubmit={passwordFormMethods.handleSubmit(passwordOnSubmit)}>
          <Container className={classes.container}>
            <FormSeparator label={t('accountSettingsPage.form.changePassword')} />
            <PasswordInput
              name="newPassword"
              label={t('setPasswordPage.form.newPassword.label')}
              placeholder={t('setPasswordPage.form.newPassword.placeholder')}
            />
            <PasswordInput
              name="repeatPassword"
              label={t('setPasswordPage.form.repeatNewPassword.label')}
              placeholder={t('setPasswordPage.form.repeatNewPassword.placeholder')}
            />
          </Container>
          <Container className={classes.submitButton}>
            <Button color="primary" fullWidth variant="contained" type="submit">
              {t('accountSettingsPage.form.submitButton')}
            </Button>
          </Container>
        </form>
      </FormProvider>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    margin: `0 0 ${theme.spacing(12)} 0`,
    [theme.breakpoints.down('sm')]: {
      margin: `0 0 ${theme.spacing(6)} 0`
    }
  },
  container: {
    marginBottom: '2rem'
  },
  submitButton: {
    marginTop: theme.spacing(8),
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(6)
    }
  }
}))
