import { apiClient } from 'common/utils/api'
import * as z from 'zod'
import { patientPersonalInfoSchema } from './constants'

const patientDetailsResponseSchema = z.object({ ...patientPersonalInfoSchema, nfz: z.string() })

export type PatientDetailsRequestResponse = z.TypeOf<typeof patientDetailsResponseSchema>

export const getPatientDetails = async (token: string): Promise<PatientDetailsRequestResponse> => {
  const response = await apiClient(token).get('Patients').json()

  return patientDetailsResponseSchema.parse(response)
}
