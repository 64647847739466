export type RawQuestionData = {
  order: number
  type: string
  title: {
    regularStart: string
    highlightedPart: string
    regularEnd: string
  }
  options?: string[] | { value: string; showPill: boolean }[]
  wrongAnswer?: string
  inputPlaceholder?: string
  description?: string
  yesText?: string
  noText?: string
  correctAnswer?: boolean
  showPossibleMedicationsHint?: boolean
}

export type RawPossibleMedications = {
  [id: string]: string
}

export enum QuestionType {
  multipleChoice = 'MULTIPLE_CHOICE',
  singleChoice = 'SINGLE_CHOICE',
  text = 'TEXT'
}

export interface BaseQuesion {
  order: number
  type: QuestionType
  title: {
    regularStart?: string
    highlightedPart?: string
    regularEnd?: string
  }
}

export interface MultipleChoiceQuestion extends BaseQuesion {
  options: { value: string; showPill: boolean }[]
  wrongAnswer: string
  inputPlaceholder: string
}
export interface SingleChoiceQuestion extends BaseQuesion {
  description: string
  yesText: string
  noText: string
  correctAnswer: boolean
}

export interface TextQuestion extends BaseQuesion {
  showPossibleMedicationsHint?: boolean
  inputPlaceholder: string
}

export type SurveyQuestion = MultipleChoiceQuestion | SingleChoiceQuestion | TextQuestion

export interface StaticData {
  questions: SurveyQuestion[]
  possibleMedications: { [id: string]: string }
}
