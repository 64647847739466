import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

interface ThemeProps {
  children: React.ReactNode
}

const white = '#ffffff'
const black = '#000000'
const matterhorn = '#4d4d4d'
const greenCyan = '#00D1A9'
const whisper = '#ECECEC'
const dimGrey = '#707070'
const distinguishedLightGray = '#A0A0A0'

export const customThemeParts = {
  customColors: {
    white,
    black,
    fontGray: matterhorn,
    backgroundGray: matterhorn,
    lightBlack: `${black}12`,
    opaqueWhite: `${white}7f`,
    lightGrey: whisper,
    opaqueGrey: `#CCCCCC`,
    borderGrey: dimGrey,
    distinguishedLightGray
  },
  boxShadow: {
    lightGrey: `0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.02)`,
    solidGrey: '0px 8px 16px rgba(77, 77, 77, 0.2)'
  }
}

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 920,
    lg: 1440
  }
})

export const themeDefinition = createTheme({
  palette: {
    primary: {
      main: greenCyan
    },
    secondary: {
      main: matterhorn
    },
    text: {
      primary: matterhorn
    }
  },
  breakpoints,
  typography: {
    fontFamily: ['Lato', 'Helvetica', '"Open Sans"', 'sans-serif'].join(',')
  },
  spacing: (factor) => `${0.5 * factor}rem`,
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 'clamp(2.7em, 3vw, 3.36em)',
        fontWeight: 900,
        color: matterhorn,
        whiteSpace: 'pre-line',
        [breakpoints.down('sm')]: {
          fontSize: '2.05rem'
        },
        [breakpoints.down('xs')]: {
          fontSize: '1.575rem'
        }
      },
      h2: {
        fontSize: 'clamp(2.7em, 3vw, 3.2em)',
        fontWeight: 900,
        color: matterhorn,
        [breakpoints.down('sm')]: {
          fontSize: '1.5rem'
        }
      },
      h3: {
        fontSize: 'clamp(1.7em, 2vw, 2.25em)',
        fontWeight: 400,
        color: matterhorn
      },
      h4: {
        fontSize: 'clamp(2.3em, 2.5vw, 2.7em)'
      },
      body1: {
        fontWeight: 400,
        fontSize: 'clamp(1rem, 1.3vw, 1.1875rem)',
        margin: `1rem 0`,
        [breakpoints.down('sm')]: {
          fontSize: '1.15rem'
        },
        [breakpoints.down('xs')]: {
          fontSize: '1rem'
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'unset',
        fontSize: '1.125rem',
        [breakpoints.down('xs')]: {
          fontSize: '1rem'
        }
      },
      contained: {
        margin: '0.5rem 0',
        padding: '1rem 0',
        borderRadius: '3rem',
        boxShadow: 'unset',
        backgroundColor: 'unset',
        border: `2px solid ${matterhorn}`,
        fontWeight: 700,
        [breakpoints.down('xs')]: {
          padding: '0.5rem 0'
        }
      },
      outlined: {
        margin: '0.5rem 0',
        padding: '1rem 0',
        borderRadius: '3rem',
        boxShadow: 'unset',
        backgroundColor: 'unset',
        border: `1.5px solid ${matterhorn} !important`,
        fontWeight: 700,
        [breakpoints.down('xs')]: {
          padding: '0.5rem 0'
        }
      },
      outlinedPrimary: {
        backgroundColor: 'transparent',
        color: matterhorn,

        '&:hover': {
          backgroundColor: matterhorn,
          color: white,
          border: `1.5px solid ${matterhorn} !important`
        }
      },
      outlinedSecondary: {
        backgroundColor: 'transparent',
        color: white,
        border: `1.5px solid ${white} !important`,

        '&:hover': {
          backgroundColor: white,
          color: matterhorn,
          border: `1.5px solid ${white} !important`
        }
      },
      containedPrimary: {
        backgroundColor: greenCyan,
        border: 'none',
        color: white
      },
      containedSecondary: {
        backgroundColor: white,
        border: 'none',
        color: greenCyan,

        '&:hover': {
          backgroundColor: white
        }
      }
    }
  },
  ...customThemeParts
})

export function Theme({ children }: ThemeProps): JSX.Element {
  return (
    <ThemeProvider theme={themeDefinition}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
