import { Checkbox, CheckboxProps, FormControlLabel, makeStyles } from '@material-ui/core'
import { ChangeEvent, ReactNode } from 'react'

interface ButtonProps extends CheckboxProps {
  error: boolean
}

const Button = ({ error, ...rest }: ButtonProps) => {
  const classes = useStyle()

  return <Checkbox {...rest} className={classes.checkbox} />
}

interface CheckboxBaseProps {
  value: boolean
  onChange: (_: ChangeEvent<Record<string, unknown>>, checked: boolean) => void
  className?: string
  label?: ReactNode
  error?: boolean
}

export const CheckboxBase = ({ error, value, onChange, className = '', label }: CheckboxBaseProps) => {
  const classes = useStyle()

  return (
    <FormControlLabel
      checked={value}
      value={value}
      onChange={onChange}
      className={`${classes.controlLabel} ${className}`}
      control={<Button error={!!error} />}
      label={label}
    />
  )
}

const useStyle = makeStyles((theme) => ({
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main
    },

    '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
      background: 'transparent',
      backgroundColor: 'rgba(0,0,0,0)'
    },

    '&:hover': {
      background: 'transparent',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  },
  controlLabel: {
    marginLeft: '-1px',

    '& > .MuiFormControlLabel-label': {
      lineHeight: 'normal',
      letterSpacing: '0.013rem',
      color: theme.palette.text.primary,
      fontSize: '0.95rem',
      margin: '0.5rem 0 0.5rem 0.5rem'
    },

    '&> .MuiCheckbox-root': {
      padding: '0px',
      display: 'flex',
      alignItems: 'center'
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.text.primary
    }
  }
}))
