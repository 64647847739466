export const DropdownIcon = () => (
  <svg
    className="MuiSvgIcon-root MuiSelect-icon"
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L9 9L17 1" stroke="#4D4D4D" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
