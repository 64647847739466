import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Grid } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'common/components'
import { RoutesDefinition } from 'routing/constants/RoutesDefinition'

import { useSession } from 'state'
import greenMeds from '../images/greenMeds.svg'

export const Starting = (): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { isLogged } = useSession()

  const goToSurvey = () => history.push(RoutesDefinition.surveyStart)

  const handleCTAClick = () => history.push(isLogged ? RoutesDefinition.accountDashboard : RoutesDefinition.authLogin)

  return (
    <Grid id="aboutUs" container direction="row" item className={classes.sectionWrapper}>
      <Grid
        className={classes.leadContainer}
        container
        xs={12}
        md={7}
        lg={6}
        justifyContent="center"
        direction="column"
        item
      >
        <Typography variant="h1">{t('landingPage.startingSection.header')}</Typography>
        <Typography variant="body1">
          <Trans i18nKey="landingPage.startingSection.description" />
        </Typography>
        <Box className={classes.ctaWrapper}>
          {!isLogged && (
            <Button color="primary" fullWidth variant="contained" handleButtonClick={goToSurvey}>
              <Trans i18nKey="landingPage.startingSection.cta1" />
            </Button>
          )}
          <Button variant="contained" fullWidth handleButtonClick={handleCTAClick}>
            <Trans i18nKey="landingPage.startingSection.cta2" />
          </Button>
        </Box>
      </Grid>
      <Grid
        className={classes.imageContainer}
        container
        justifyContent="center"
        xs={12}
        md={5}
        lg={6}
        alignItems="center"
        direction="column"
        item
      >
        <img src={greenMeds} alt="StartingImage" />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    minHeight: '100vh',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  leadContainer: {
    padding: `0 ${theme.spacing(4)} 0 0`,

    '& > p': {
      maxWidth: '570px'
    },

    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  ctaWrapper: {
    margin: '1rem 0',
    width: '65%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      width: '100%'
    }
  },
  coloredSection: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: `calc(6.85vh + 1rem) 0 ${theme.spacing(3)} !important`
    },

    '& > img': {
      width: '90%'
    }
  }
}))
