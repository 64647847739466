import { apiClient } from 'common/utils/api'
import { ContactFormData } from '../types'

export const submitContactForm = async ({ name, email, message }: ContactFormData): Promise<void> => {
  await apiClient().post('contact-form', {
    json: {
      name,
      issuerEmail: email,
      message
    }
  })
}
