import { useContext } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { SurveyContext } from 'state'
import { StaticData } from 'features/survey/types'

export const PossibleMedicationsHint = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data } = useQuery<StaticData>('staticData')
  const hintsData = data?.possibleMedications || {}

  const { selectedCondition } = useContext(SurveyContext)

  const hintContent = selectedCondition && hintsData[selectedCondition]

  if (!hintContent) return <></>

  return (
    <div className={classes.container}>
      <Typography className={classes.header}>{t('surveyPage.shared.possibleMedicationsHeader')}</Typography>
      {hintContent}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: `0 ${theme.spacing(2)}`
    }
  },
  header: {
    fontWeight: 700,
    fontSize: 'clamp(1.1em, 1.25vw, 1.5em)'
  }
}))
