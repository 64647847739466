import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SubmitHandler } from 'react-hook-form'

import { DualColorChoiceLink } from 'common/components/DualColorChoiceLink'
import { RoutesDefinition } from 'routing/constants/RoutesDefinition'
import { LoginForm } from './LoginForm'
import { LoginFormData } from '../types'

interface LoginProps {
  onSubmit: SubmitHandler<LoginFormData>
  isProcessing?: boolean
  isError: boolean
}

export const Login = ({ isProcessing, onSubmit, isError }: LoginProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Grid container item xs={12} sm={8} md={6} direction="column" justifyContent="center" alignItems="center">
      <Typography variant="h1" className={classes.header}>
        {t('loginPage.header')}
      </Typography>
      <LoginForm isProcessing={isProcessing} onSubmit={onSubmit} isError={isError} />
      <DualColorChoiceLink routingPath={RoutesDefinition.authLogin} translationPath="loginPage.haveAccount" />
    </Grid>
  )
}

const useStyle = makeStyles((theme) => ({
  header: {
    padding: `0 0 ${theme.spacing(10)} 0`
  }
}))
