import { CircularProgress, CircularProgressProps, makeStyles } from '@material-ui/core'

export const Loader = ({ ...rest }: CircularProgressProps): JSX.Element => {
  const classes = useStyle()

  return (
    <div className={classes.loaderWrapper}>
      <CircularProgress {...rest} />
    </div>
  )
}

const useStyle = makeStyles(() => ({
  loaderWrapper: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}))
