import { z } from 'zod'

export const loginFormSchema = z.object({
  email: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.emailRequired')
        .email('errors.incorrectEmail')
        .safeParse(val)

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    }),
  password: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.passwordRequired')
        .min(8, { message: 'errors.incorrectPassword' })
        .safeParse(val)

      const includesLowerCase = /[a-z]/.test(val)
      const includesUpperCase = /[A-Z]/.test(val)
      const includesDigit = /[\d]/.test(val)
      const includesSpecialChar = /[^\d\w\s]/.test(val)

      if (!includesLowerCase || !includesUpperCase || !includesDigit || !includesSpecialChar) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errors.incorrectPassword'
        })
      }
      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0])
      }
    })
})

export type LoginFormData = z.infer<typeof loginFormSchema>
