import { TextFieldProps } from '@material-ui/core/TextField'
import { useController, useFormContext } from 'react-hook-form'
import { TextInput } from 'common/components'
import { FormErrorMessage } from './FormErrorMessage'

export interface InputProps extends Omit<TextFieldProps, 'onChange' | 'inputRef' | 'value' | 'error'> {
  name: string
  defaultValue?: string
}

export const FormTextField = ({ name, defaultValue, ...rest }: InputProps): JSX.Element => {
  const {
    formState: { errors }
  } = useFormContext()

  const {
    field: { ref, value, ...fieldProps },
    fieldState: { error }
  } = useController({
    name,
    defaultValue
  })

  return (
    <TextInput
      {...rest}
      {...fieldProps}
      value={value}
      inputRef={ref}
      error={!!error}
      helperText={<FormErrorMessage errors={errors} name={name} />}
    />
  )
}
