import { apiClient } from 'common/utils/api'
import { StartingContactDataForm } from '../types'

export const submitContactDataForm = async ({
  firstName,
  phone,
  lastName,
  email
}: StartingContactDataForm): Promise<void> => {
  await apiClient().post('accounts/Leads', {
    json: {
      firstName,
      lastName,
      email,
      phone
    }
  })
}
