import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Dispatch, SetStateAction } from 'react'
import DayJsUtils from '@date-io/dayjs'
import dayjs, { Dayjs } from 'dayjs'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { YEAR_MONTH_DAY } from 'common/constants'
import { AvailableTerm } from 'features'

/**
 * Usage:
 * const [calVal, setCalVal] = useState(dayjs(new Date()))
 * const maxDate = calcDateMonthFromNow()
 * const availableDays = [dayjs(new Date('Some date'))]
 *
 * <Calendar maxDate={dayjs(maxDate)} value={calVal} onChange={setCalVal} availableDays={availableDays} />
 */

interface CalendarProps {
  maxDate: Dayjs
  value: Dayjs
  onChange: Dispatch<SetStateAction<Dayjs>>
  availableTerms: AvailableTerm[]
  setMonthToFetch: (value: number) => void
}

export const calcDateMonthFromNow = () => {
  const currentDate = new Date().getDate()
  const newDate = new Date()
  newDate.setDate(currentDate + 31)
  return dayjs(newDate)
}

export const Calendar = ({ maxDate, value, onChange, availableTerms, setMonthToFetch }: CalendarProps) => {
  const classes = useStyle()
  const { i18n } = useTranslation()

  const shouldDisableDate = (date: Dayjs) => {
    return availableTerms.filter((term) => term.date === date.format(YEAR_MONTH_DAY)).length === 0
  }

  return (
    <MuiPickersUtilsProvider locale={i18n.language} utils={DayJsUtils}>
      <Grid container justifyContent="center" className={classes.datePicker}>
        <DatePicker
          disableToolbar
          disablePast
          variant="static"
          maxDate={maxDate}
          shouldDisableDate={(day) => shouldDisableDate(dayjs(day))}
          value={value}
          onChange={(date) => onChange(dayjs(date))}
          onMonthChange={(date) => setMonthToFetch((date?.get('M') as number) + 1)}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

const useStyle = makeStyles((theme) => ({
  datePicker: {
    '& .MuiPickersBasePicker-pickerView': {
      '& .MuiPickersCalendarHeader-switchHeader': {
        '& .MuiPickersSlideTransition-transitionContainer': {
          height: '30px'
        }
      }
    },
    overflow: '',
    '& p.MuiTypography-root.MuiTypography-body1': {
      margin: 0,
      fontWeight: 900
    },
    '& .MuiPickersDay-daySelected': {
      color: theme.customColors.white,
      backgroundColor: theme.palette.text.primary
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      backgroundColor: 'unset'
    },
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      backgroundColor: 'unset',
      transform: 'scale(1.5)',
      margin: '10% 5%',

      [theme.breakpoints.down('sm')]: {
        transform: 'scale(1.25)'
      },

      [theme.breakpoints.down('xs')]: {
        transform: 'scale(1.15)'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPickersStaticWrapper-staticWrapperRoot': {
        margin: '15% 5%'
      }
    }
  }
}))
